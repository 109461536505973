import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { PrimaryButton } from '../../../../components/Button/Button'
import Container from '../Container/Container'
import ReadingContainer from '../../../../components/ReadingContainer/ReadingContainer'

const StartButton = styled(PrimaryButton)`
  margin: auto;
`

const StartPageContent = ({
  description,
  startContent,
  contentComponent: HTMLFromBodyMD,
  startText,
  start,
}) => (
  <Container>
    {description && <h4>{description}</h4>}
    <ReadingContainer>
      <HTMLFromBodyMD>{startContent}</HTMLFromBodyMD>
    </ReadingContainer>
    <StartButton onClick={() => start()} type="button">
      {startText || 'Start'}
    </StartButton>
  </Container>
)

StartPageContent.propTypes = {
  description: PropTypes.string,
  start: PropTypes.func.isRequired,
  startContent: PropTypes.string,
  contentComponent: PropTypes.func.isRequired,
  startText: PropTypes.string,
}

StartPageContent.defaultProps = {
  description: null,
  startContent: null,
  startText: null,
}
export default StartPageContent
