import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const Container = styled.div`
  margin: auto;
  padding: 20px;
  max-width: 90%;
`

const BarContainer = styled.div`
  display: grid;
  grid-template-columns: [barStart] 1fr [barEnd];
  grid-template-rows: 1fr;
  align-content: center;
  width: 100%;
  height: 10px;
`

const Line = styled.div`
  height: 4px;
  margin: auto;
  width: 100%;
  background-color: ${({ theme }) => theme.color.secondary};
  grid-column: barStart/barEnd;
  grid-row: 1;
  align-self: center;
`
const StepContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  grid-column: barStart/barEnd;
  grid-row: 1;
  z-index: 1;
`

const Step = styled.div`
  border-radius: 3px;
  background-color: #2a2a2a;
  color: #fff;
  padding: 6px;
  display: flex;
  align-items: center;
  text-align: center;
`

const CurrentStep = styled(Step)`
  background-color: ${({ theme }) => theme.color.primary};
`

const ProgressBar = ({ steps, currentStep }) => {
  return (
    <Container>
      <BarContainer>
        <Line />
        <StepContainer>
          {currentStep === 0 ? (
            <CurrentStep>Start</CurrentStep>
          ) : (
            <Step>Start</Step>
          )}

          {steps.map((step, i) => {
            const { stepTitle } = step
            if (i + 1 === currentStep) {
              return <CurrentStep key={stepTitle}>{i + 1}</CurrentStep>
            }
            return <Step key={stepTitle}>{i + 1}</Step>
          })}
          {currentStep === steps.length + 1 ? (
            <CurrentStep>End</CurrentStep>
          ) : (
            <Step>End</Step>
          )}
        </StepContainer>
      </BarContainer>
    </Container>
  )
}

ProgressBar.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.any).isRequired,
  currentStep: PropTypes.number.isRequired,
}

export default ProgressBar
