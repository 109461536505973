import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

const eventLogAdapter = createEntityAdapter()

let logIdNo = -2 // to ignore the two redux set-up actions

const eventLogSlice = createSlice({
  name: 'eventLog',
  initialState: eventLogAdapter.getInitialState(),
  extraReducers: builder => {
    builder.addMatcher(
      action => {
        return action
      },
      (state, action) => {
        const { payload, type } = action
        logIdNo += 1
        const d = new Date()
        const newPayload = {
          id: logIdNo,
          type,
          time: d.toISOString(),
          payload,
        }
        return eventLogAdapter.addOne(state, newPayload)
      }
    )
  },
})

export default eventLogSlice.reducer
