import { useState, useRef, useCallback, useMemo, useEffect } from 'react'

/**
 * counts down from a set interval and states the timeLeft and whether the countdown has already run
 * Actions are available to start, pause, resume and reset the countdown
 * @param {number} timeToCount number of milliseconds to count down from
 * @param {number} interval number of milliseconds between clock changes
 * @returns [{number,boolean},{function}] state and actions
 */
const useCountDown = (timeToCount = 60 * 1000, interval = 1000) => {
  const [timeLeft, setTimeLeft] = useState(0)
  const [timerHasRun, setTimerHasRun] = useState(false)
  const timer = useRef({})

  const run = ts => {
    if (!timer.current.started) {
      timer.current.started = ts
      timer.current.lastInterval = ts
      // to go down to the first second when countdown starts
      setTimeLeft(timeToCount - 1)
    }

    const localInterval = Math.min(interval, timer.current.timeLeft || Infinity)
    const timeDif = ts - timer.current.lastInterval

    if (timeDif >= localInterval) {
      timer.current.lastInterval += timeDif
      setTimeLeft(timeRemaining => {
        timer.current.timeLeft = timeRemaining - timeDif
        return timer.current.timeLeft
      })
    }

    if (ts - timer.current.started < timer.current.timeToCount) {
      timer.current.requestId = window.requestAnimationFrame(run)
    } else {
      timer.current = {}
      setTimerHasRun(true)
      setTimeLeft(0)
    }
  }

  const start = useCallback(ttc => {
    window.cancelAnimationFrame(timer.current.requestId)

    const newTimeToCount = ttc !== undefined ? ttc : timeToCount
    timer.current.started = null
    timer.current.lastInterval = null
    timer.current.timeToCount = newTimeToCount
    timer.current.requestId = window.requestAnimationFrame(run)

    setTimeLeft(newTimeToCount)
  }, [])

  const pause = useCallback(() => {
    window.cancelAnimationFrame(timer.current.requestId)
    timer.current.started = null
    timer.current.lastInterval = null
    timer.current.timeToCount = timer.current.timeLeft
  }, [])

  const resume = useCallback(() => {
    if (!timer.current.started && timer.current.timeLeft > 0) {
      window.cancelAnimationFrame(timer.current.requestId)
      timer.current.requestId = window.requestAnimationFrame(run)
    }
  }, [])

  const reset = useCallback(() => {
    if (timer.current.timeLeft) {
      window.cancelAnimationFrame(timer.current.requestId)
      timer.current = {}
      setTimeLeft(0)
      setTimerHasRun(false)
    }
  }, [])

  const actions = useMemo(() => ({ start, pause, resume, reset }), [])

  useEffect(() => reset, [])

  return [{ timeLeft, timerHasRun }, actions]
}

export default useCountDown
