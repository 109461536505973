import React from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useSelector } from 'react-redux'
import Timer from '../../../components/Timer/Timer'
import ProgressBar from '../../../components/ProgressBar/ProgressBar'
import { adventureSelector } from '../reducers/adventureSlice'

const TopSection = ({
  title,
  steps,
  timer,
  timeLeft,
  showHintsUsed,
  showProgressBar,
  timerHasRun,
}) => {
  const { hintsUsed, currentStep } = useSelector(
    adventureSelector,
    shallowEqual
  )
  let mins = timeLeft ? Math.floor(timeLeft / 60000).toFixed(0) : timer
  let seconds = timeLeft ? `${Math.floor((timeLeft / 1000) % 60)}` : '0'
  if (seconds < 10) {
    seconds = `0${seconds}`
  }
  if (timerHasRun) {
    mins = 0
  }
  return (
    <>
      <h1>{title}</h1>
      {showProgressBar && (
        <ProgressBar currentStep={currentStep} steps={steps} />
      )}
      {!!timer && (
        <Timer>
          {mins}:{seconds}
        </Timer>
      )}
      {showHintsUsed && (
        <Timer>
          {hintsUsed} hint{hintsUsed === 1 ? '' : 's'} used
        </Timer>
      )}
    </>
  )
}

TopSection.propTypes = {}

export default TopSection
