import React from 'react'
import PropTypes from 'prop-types'
import Content from '../components/Content/Content'
import Adventure from '../modules/Adventure/Adventure'

export const AdventurePageTemplate = ({ html, ...props }) => (
  <Adventure {...props} startContent={html} />
)

AdventurePageTemplate.propTypes = {
  html: PropTypes.string,
  contentComponent: PropTypes.func,
}

AdventurePageTemplate.defaultProps = {
  contentComponent: Content,
  html: null,
}
