import { AdventurePageTemplate } from '../pageTemplates/AdventurePageTemplate'
import { HTMLContent } from '../components/Content/Content'
import { Helmet } from 'react-helmet'
import Layout from '../Layouts/Layout'
import PropTypes from 'prop-types'
import React from 'react'
import { graphql } from 'gatsby'

const AdventurePage = ({ data }) => {
  const { markdownRemark } = data || {}
  const { html, frontmatter, fields } = markdownRemark || {}
  const { description, title } = frontmatter || {}
  return (
    <Layout noHeader>
      <Helmet titleTemplate="%s | Adventure">
        <title>{`${title}`}</title>
        <meta name="description" content={`${description}`} />
      </Helmet>
      <AdventurePageTemplate
        html={html}
        {...frontmatter}
        {...fields}
        contentComponent={HTMLContent}
      />
    </Layout>
  )
}

AdventurePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }).isRequired,
}

export default AdventurePage

export const pageQuery = graphql`
  query AdventureByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        description
        tags
        timer
        showHintsUsed
        showProgressBar
        startText
      }
      fields {
        steps {
          type
          stepTitle
          blurb
          content
          answerFormat
          answer
          messages {
            msg
            author
            responseAnswers
            userType
            incorrectMsgs
          }
          hints
          nextText
        }
      }
    }
  }
`
