import React from 'react'
import PropTypes from 'prop-types'
import { configureStore } from '@reduxjs/toolkit'
import { Provider as ReduxProvider } from 'react-redux'
import styled from '@emotion/styled'
import Steps from './Steps/Steps'
import rootReducer from './reducers'
import TopSection from './TopSection/TopSection'
import useCountDown from '../../hooks/useCountDown/useCountDown'

const store = configureStore({
  reducer: rootReducer,
})

const Container = styled.div`
  margin: auto;
  max-width: 1400px;
  text-align: center;
  margin-bottom: 2em;
`

const Adventure = ({
  steps,
  title,
  showProgressBar,
  timer,
  showHintsUsed,
  ...otherProps
}) => {
  const [{ timeLeft, timerHasRun }, countDownActions] = useCountDown(
    timer * 1000 * 60,
    1000
  )

  return (
    <ReduxProvider store={store}>
      <Container>
        <TopSection
          title={title}
          steps={steps}
          timeLeft={timeLeft}
          timer={timer}
          showHintsUsed={showHintsUsed}
          showProgressBar={showProgressBar}
          timerHasRun={timerHasRun}
        />
        <Steps {...otherProps} {...countDownActions} steps={steps} />
      </Container>
    </ReduxProvider>
  )
}

Adventure.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  title: PropTypes.string,
  showProgressBar: PropTypes.bool,
  showHintsUsed: PropTypes.bool,
  timer: PropTypes.number,
}

Adventure.defaultProps = {
  title: null,
  steps: [],
  showProgressBar: false,
  showHintsUsed: false,
  timer: 0,
}

export default Adventure
