import React, { useState } from 'react'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import {
  messageController,
  messageState,
} from '../../../../reducers/messagesSlice'
import { SmallButton } from '../../../../../../components/Button/Button'
import ThreeDotLoader from '../../../../../../components/ThreeDotLoader/ThreeDotLoader'
import ContentEditableDiv from '../../../../../../components/ContentEditableDiv/ContentEditableDiv'

const Container = styled.div`
  position: relative;
`

const MessageInputArea = styled.div`
  background-color: ${({ theme }) => theme.color.muted};
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 8px;
`

const MessageInputContainer = styled.div`
  margin: 9px;
  font-size: 15px;
  line-height: 20px;
  border-radius: 25px;
  background-color: #fff;
  text-align: left;
  position: relative;
  width: 100%;
`

const SendButtonContainer = styled.div`
  display: flex;
  align-items: center;
  height: 46px;
  padding: 9px;
`
const SendButton = styled(SmallButton)`
  background-color: ${({ theme }) => theme.color.primary};
  padding: 4px;
  color: #fff;
  border: none;
  height: 100%;
  border-radius: 50%;
`
const Loader = styled(ThreeDotLoader)`
  position: absolute;
  top: -48px;
  left: 0;
  right: 0;
`

const SendMessage = () => {
  const dispatch = useDispatch()
  const [msgInState, updateMsgInState] = useState('')
  const { gameTyping } = useSelector(messageState)
  return (
    <Container>
      {gameTyping && <Loader />}
      <MessageInputArea>
        <MessageInputContainer>
          <ContentEditableDiv
            submissionAction={msg => dispatch(messageController(msg))}
            updateAction={updateMsgInState}
          />
        </MessageInputContainer>
        <SendButtonContainer
          onClick={() => dispatch(messageController(msgInState))}
        >
          <SendButton>Send</SendButton>
        </SendButtonContainer>
      </MessageInputArea>
    </Container>
  )
}

SendMessage.propTypes = {}

export default SendMessage
