import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { adventureSelector, changeStep } from './adventureSlice'

let msgId = -1

const messagesAdapter = createEntityAdapter()

const messagesSlice = createSlice({
  name: 'messages',
  initialState: messagesAdapter.getInitialState({
    latestCannedMsgId: undefined,
    latestUserMsgId: undefined,
    gameTyping: false,
    incorrectMsgCount: 0,
  }),
  reducers: {
    addMessage: {
      reducer: (state, action) => {
        const { payload } = action
        const { userType, id } = payload
        console.log({ userType }, userType === 'adventure', payload)
        if (userType === 'adventure') {
          state.latestCannedMsgId = id
          state.gameTyping = false
          state.incorrectMsgCount = 0
        }
        if (userType === 'user') {
          state.latestUserMsgId = id
        }
        if (userType === 'incorrect') {
          state.incorrectMsgCount += 1
          state.gameTyping = false
        }
        messagesAdapter.addOne(state, payload)
      },
      prepare: msg => {
        msgId += 1
        return { payload: { ...msg, id: msgId } }
      },
    },
    gameTyping: (state, { payload }) => {
      state.gameTyping = payload
    },
  },
})

// Actions
export const { addMessage, gameTyping } = messagesSlice.actions

// Selectors
export const allMessagesSelector = messagesAdapter.getSelectors(
  state => state[messagesSlice.name]
).selectAll

export const messageEntities = messagesAdapter.getSelectors(
  state => state[messagesSlice.name]
).selectEntities

export const messageState = state => state[messagesSlice.name]

// Controllers
export const randomTimeGenerator = (maxTime, minTime) => {
  const randomTime = Math.random() * (maxTime - minTime)
  return (randomTime + minTime) * 1000
}

const changeToNextStep = (
  answerFormat,
  messages,
  currentStepMsg,
  nextMsg,
  action
) => {
  if (
    answerFormat === 'textMessage' &&
    ((messages.length - 1 === currentStepMsg + 1 && !nextMsg.responseAnswers) ||
      messages.length - 1 < currentStepMsg + 1)
  ) {
    action()
  }
}

export const messageController = payload => (dispatch, getState) => {
  const { userType, msg } = payload
  dispatch(messagesSlice.actions.addMessage(payload))
  if (userType === 'user') {
    const state = getState()
    const { latestCannedMsgId, entities, incorrectMsgCount } = messageState(
      state
    )
    const { responseAnswers = [], incorrectMsgs = [] } = entities[
      latestCannedMsgId
    ]

    if (
      responseAnswers &&
      (responseAnswers.length === 0 ||
        responseAnswers.some(answer =>
          new RegExp(`\\b${msg}\\b`, 'gi').test(answer)
        ))
    ) {
      const { steps, currentStep, currentStepMsg } = adventureSelector(state)
      const { messages, answerFormat } = steps[currentStep - 1]
      const nextMsg = messages[currentStepMsg + 1] || null

      if (nextMsg) {
        dispatch(gameTyping(true))
        setTimeout(() => {
          dispatch(addMessage(nextMsg))
          setTimeout(() => {
            changeToNextStep(
              answerFormat,
              messages,
              currentStepMsg,
              nextMsg,
              () => dispatch(changeStep())
            )
          }, randomTimeGenerator(1, 0.5))
        }, randomTimeGenerator(3, 1))
      } else {
        changeToNextStep(answerFormat, messages, currentStepMsg, nextMsg, () =>
          dispatch(changeStep())
        )
      }
    } else {
      dispatch(gameTyping(true))
      const wrongAnswerMessage = [...(incorrectMsgs || []), `try using a hint`]
      console.log(wrongAnswerMessage)
      const index =
        incorrectMsgCount < wrongAnswerMessage.length
          ? incorrectMsgCount
          : wrongAnswerMessage.length - 1

      setTimeout(() => {
        dispatch(
          addMessage({
            msg: wrongAnswerMessage[index],
            userType: 'incorrect',
          })
        )
      }, randomTimeGenerator(1, 0.5))
    }
  }
}

// Reducer export
export default messagesSlice.reducer
