import React from 'react'
import PropTypes from 'prop-types'
import ContentAndPhone from './ContentAndPhone/ContentAndPhone'
import NextButtonScreen from './NextButtonScreen/NextButtonScreen'

/**
 * All step props can be found by looking in the netlifycms config.yaml
 * @param {string} type prop defining what type of screen is used
 */
const StepScreen = ({ type, ...stepProps }) => {
  switch (type) {
    case 'contentAndPhone':
    case 'nextButton':
      return <ContentAndPhone {...stepProps} type={type} />

    default:
      return <NextButtonScreen {...stepProps} />
  }
}

StepScreen.propTypes = {
  type: PropTypes.string.isRequired,
}

export default StepScreen
