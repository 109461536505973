import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { useDispatch } from 'react-redux'
import { changeStep } from '../../../reducers/adventureSlice'
import { PrimaryButton } from '../../../../../components/Button/Button'
import { HTMLContent } from '../../../../../components/Content/Content'

const NextStepButton = styled(PrimaryButton)`
  margin: auto;
`

const NextButtonScreen = ({ blurb, content, nextText }) => {
  const dispatch = useDispatch()
  return (
    <div>
      <HTMLContent>{blurb}</HTMLContent>
      <HTMLContent>{content}</HTMLContent>
      <NextStepButton onClick={() => dispatch(changeStep())}>
        {nextText}
      </NextStepButton>
    </div>
  )
}

NextButtonScreen.propTypes = {
  blurb: PropTypes.string,
  content: PropTypes.string,
  nextText: PropTypes.string,
}

NextButtonScreen.defaultProps = {
  blurb: null,
  content: null,
  nextText: 'Next',
}

export default NextButtonScreen
