import styled from '@emotion/styled'

const Container = styled.div`
  width: 100%;
  text-align: justify;
  img {
    width: 100%;
  }
`
export default Container
