import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import IphoneX from '@bit/archilton.react-components.i-phone-x'
import { useTheme } from 'emotion-theming'
import { ClassNames } from '@emotion/core'
import { useDispatch, useSelector } from 'react-redux'
import { adventureSelector, changeStep } from '../../../reducers/adventureSlice'
import AnswerFormat from './AnswerFormat/AnswerFormat'
import Hint from './Hint/Hint'
import ContentContainer from '../../Container/Container'
import { HTMLContent } from '../../../../../components/Content/Content'
import Messages from './Messages/Messages'
import SendMessage from './SendMessage/SendMessage'
import { PrimaryButton } from '../../../../../components/Button/Button'
import ReadingContainer from '../../../../../components/ReadingContainer/ReadingContainer'

const PuzzleContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ type }) =>
    type === 'phoneOnly' ? '1fr' : '1fr auto'};
  position: relative;
  grid-gap: 20px;

  img {
    border-radius: ${({ theme }) => theme.borderRadius};
  }
`

const StickyDiv = styled.div`
  position: sticky;
  top: 20px;
  padding: 10px;
  margin: auto;
`
const IPhone = styled(IphoneX)`
  position: relative;
`

const NextStepButton = styled(PrimaryButton)`
  margin: auto;
`

const ContentAndPhone = ({
  answerFormat,
  blurb,
  content,
  img,
  answer,
  messages,
  type,
  hints,
  nextText,
}) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { showNextActive, hintsUsedThisStep } = useSelector(adventureSelector)
  return (
    <div>
      <ContentContainer>
        <ReadingContainer>
          <HTMLContent>{blurb}</HTMLContent>
        </ReadingContainer>
      </ContentContainer>
      <PuzzleContainer type={type}>
        <div>
          {content && (
            <ContentContainer>
              <ReadingContainer>
                <HTMLContent>{content}</HTMLContent>
              </ReadingContainer>
            </ContentContainer>
          )}
          <ReadingContainer>
            <AnswerFormat answerFormat={answerFormat} answer={answer} />
          </ReadingContainer>
        </div>
        <div>
          {messages && (
            <StickyDiv>
              <ClassNames>
                {({ css }) => (
                  <IPhone
                    toolbar
                    toolbarTitle="Agent X"
                    statusBarWrapper={css`
                      background-color: ${theme.color.secondary};
                      color: #fff;
                      img {
                        filter: invert(1);
                      }
                    `}
                    toolbarWrapper={css`
                      background-color: ${theme.color.secondary};
                      color: #fff;
                      padding: 12px 0;
                      font-weight: 600;
                      font-size: 1.2em;
                      box-shadow: 0px 10px 10px -10px rgba(0, 0, 0, 0.3);
                      z-index: 1;
                    `}
                    screenWrapper={css`
                      background-color: ${theme.color.background};
                      display: grid;
                      grid-template-rows: auto auto 1fr auto;
                    `}
                  >
                    <Messages messages={messages} />
                    <SendMessage />
                  </IPhone>
                )}
              </ClassNames>
            </StickyDiv>
          )}
        </div>
        {hints &&
          hints.map(
            (h, i) =>
              i <= hintsUsedThisStep && (
                <Hint key={h} hint={h} hintNumber={i + 1} />
              )
          )}
        {type !== 'nextButton' &&
          (!hints || hintsUsedThisStep >= hints.length) && (
            <Hint
              hint={
                answerFormat !== 'textMessage'
                  ? answer
                  : `send a text message of ${answer}`
              }
              hintPlaceholder="Reveal Answer"
            />
          )}
        {answerFormat !== 'textMessage' && (
          <NextStepButton
            onClick={() => dispatch(changeStep())}
            disabled={type !== 'nextButton' && !showNextActive}
          >
            {nextText || 'Next'}
          </NextStepButton>
        )}
      </PuzzleContainer>
    </div>
  )
}

ContentAndPhone.propTypes = {
  hints: PropTypes.arrayOf(PropTypes.string),
  nextText: PropTypes.string,
}
ContentAndPhone.defaultProps = {
  hints: [],
  nextText: null,
}
export default ContentAndPhone
