import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import {
  adventureSelector,
  toggleNextButton,
} from '../../../../reducers/adventureSlice'
import pluralise from '../../../../../../utils/pluralise/pluralise'
import Input from '../../../../../../components/Input/Input'

const answerType = (answerFormat, answer) => {
  if (answerFormat === 'string') {
    const sentence = answer && answer.trim().includes(' ')
    if (sentence) {
      const wordCount = (answer.split(' ') || []).length
      return ['sentence', 'words', wordCount]
    }
    return ['word', 'letters']
  }
  return ['number', 'digits']
}

const AnswerFormat = ({ answerFormat, answer, className }) => {
  const dispatch = useDispatch()
  const { showNextActive } = useSelector(adventureSelector)
  switch (answerFormat) {
    case 'string':
    case 'number': {
      const [primary, brokenDown, wordCount] = answerType(answerFormat, answer)
      return (
        <Input
          className={className}
          placeholder={
            answer
              ? `Enter a ${primary} with ${
                  wordCount || answer.trim().length
                } ${pluralise(brokenDown)}`
              : 'Answer needed'
          }
          onChange={e => {
            const input = `${e.target.value}` || ''
            const userAnswer = input.trim().toLowerCase()
            const trimmedAnswer = answer && answer.trim().toLowerCase()
            if (userAnswer === trimmedAnswer) {
              dispatch(toggleNextButton(true))
            } else if (showNextActive) {
              dispatch(toggleNextButton(false))
            }
          }}
          type={answerFormat}
        />
      )
    }
    default:
      return null
  }
}

AnswerFormat.propTypes = {
  answerFormat: PropTypes.string,
  answer: PropTypes.string,
  className: PropTypes.string,
}

AnswerFormat.defaultProps = {
  answer: null,
  className: null,
  answerFormat: null,
}

export default AnswerFormat
