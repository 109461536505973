import React, { useEffect, memo, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import {
  allMessagesSelector,
  addMessage,
} from '../../../../reducers/messagesSlice'
import Message from './Message/Message'

const MessageContainer = styled.div`
  overflow: auto;
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
`

const Messages = ({ messages }) => {
  const writtenMsgs = useSelector(allMessagesSelector)
  const dispatch = useDispatch()
  useEffect(() => {
    // send first message in sequence
    if (messages && messages.length > 0) {
      setTimeout(
        () => dispatch(addMessage({ ...messages[0], from: 'load' })),
        500
      )
    }
  }, [])
  const messageContainerRef = useCallback(messageEl => {
    if (messageEl) {
      messageEl.addEventListener('DOMNodeInserted', event => {
        const { currentTarget: target } = event
        target.scroll({ top: target.scrollHeight, behavior: 'smooth' })
      })
    }
  }, [])

  return (
    <MessageContainer ref={messageContainerRef}>
      {writtenMsgs.map(m => {
        const { msg, userType, id } = m
        return (
          <Message key={id} myMessage={userType === 'user'}>
            {msg}
          </Message>
        )
      })}
    </MessageContainer>
  )
}

Messages.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
}
Messages.defaultProps = {
  messages: [],
}
export default memo(Messages)
