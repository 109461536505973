import React, { memo, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import StartPageContent from './StartPageContent/StartPageContent'
import {
  adventureSelector,
  changeStep,
  loadSteps,
} from '../reducers/adventureSlice'
import StepScreen from './StepScreen/StepScreen'
import End from './End/End'

const Steps = ({
  steps,
  startContent,
  startText,
  start,
  pause,
  contentComponent,
  previewAllSteps,
}) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(loadSteps(steps))
  }, [dispatch, steps])
  const { currentStep } = useSelector(adventureSelector)
  const stepsToShow = previewAllSteps ? steps : [steps[currentStep - 1]]
  return (
    <>
      {(currentStep === 0 || previewAllSteps) && (
        <StartPageContent
          startContent={startContent}
          contentComponent={contentComponent}
          startText={startText}
          start={() => {
            start()
            dispatch(changeStep())
          }}
        />
      )}
      {((currentStep > 0 && currentStep <= steps.length) ||
        previewAllSteps) && (
        <>
          {stepsToShow.map((currentStepInformation, i) => {
            const { stepTitle } = currentStepInformation || {}
            return (
              <Fragment key={stepTitle}>
                {previewAllSteps && (
                  <div>
                    <hr style={{ margin: '2em 0' }} />
                    <h2>
                      Step {i + 1} - {stepTitle}
                    </h2>
                  </div>
                )}

                <StepScreen {...currentStepInformation} />
                {previewAllSteps && i === steps.length - 1 && (
                  <hr style={{ margin: '2em 0' }} />
                )}
              </Fragment>
            )
          })}
        </>
      )}
      {(currentStep > steps.length || previewAllSteps) && <End pause={pause} />}
    </>
  )
}

Steps.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  description: PropTypes.string,
  startContent: PropTypes.string,
  startText: PropTypes.string,
  start: PropTypes.func.isRequired,
  pause: PropTypes.func.isRequired,
  contentComponent: PropTypes.func.isRequired,
  previewAllSteps: PropTypes.bool,
}

Steps.defaultProps = {
  steps: [],
  description: null,
  startContent: null,
  previewAllSteps: false,
  startText: undefined,
}

export default memo(Steps)
