import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

const End = ({ pause }) => {
  useEffect(() => {
    pause()
  }, [])
  return <div>The End</div>
}

End.propTypes = {}

export default End
