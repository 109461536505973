import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentStep: 0,
  hintsUsed: 0,
  currentStepMsg: 0,
  steps: [],
  showNextActive: false,
  hintsUsedThisStep: 0,
}

const adventureSlice = createSlice({
  name: 'adventure',
  initialState,
  reducers: {
    changeStep: {
      reducer(state, { payload }) {
        const nextStepCount = payload || state.currentStep + 1
        state.currentStep = nextStepCount
        state.currentStepMsg = 0
        state.hintsUsedThisStep = 0
      },
    },
    addToHintCount: {
      reducer(state) {
        state.hintsUsed += 1
        state.hintsUsedThisStep += 1
      },
    },
    loadSteps: {
      reducer: (state, { payload }) => {
        state.steps = payload
      },
    },
    toggleNextButton: {
      reducer: (state, { payload }) => {
        state.showNextActive = payload || !state.showNextActive
      },
    },
  },
})

export const {
  changeStep,
  addToHintCount,
  loadSteps,
  toggleNextButton,
} = adventureSlice.actions

export const adventureSelector = state => state[adventureSlice.name]

export default adventureSlice.reducer
