import { combineReducers } from 'redux'
import adventureReducer from './adventureSlice'
import eventLogReducer from './eventLogSlice'
import messagesReducer from './messagesSlice'

export default combineReducers({
  adventure: adventureReducer,
  messages: messagesReducer,
  eventLog: eventLogReducer,
})
