import styled from '@emotion/styled'

const ThreeDotLoader = styled.div`
  border-radius: 50%;
  width: 1em;
  height: 1em;
  animation-fill-mode: both;
  animation: load7 1.8s infinite ease-in-out;
  color: ${({ color, theme }) => color || theme.color.secondary || '#fff'};
  font-size: 10px;
  margin: 2em auto;
  position: relative;
  text-indent: -9999em;

  transform: translateZ(0);

  animation-delay: -0.16s;

  :before,
  :after {
    content: '';
    position: absolute;
    top: 0;
    border-radius: 50%;
    width: 1em;
    height: 1em;
    animation-fill-mode: both;
    animation: load7 1.8s infinite ease-in-out;
  }
  :before {
    left: -1.5em;

    animation-delay: -0.32s;
  }
  :after {
    left: 1.5em;
  }

  @keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 1.5em 0 -1em;
    }
    40% {
      box-shadow: 0 1.5em 0 0;
    }
  }
`

export default ThreeDotLoader
