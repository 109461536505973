import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const MsgContainer = styled.div`
  display: flex;
  justify-content: ${({ myMessage }) =>
    !myMessage ? 'flex-start' : 'flex-end'};
  clear: both;
`
const bubbleBorderRadius = '15px'

const MsgBubble = styled.div`
  background-color: ${({ myMessage, theme }) =>
    !myMessage ? '#fff' : theme.color.primary};
  border-top-left-radius: ${bubbleBorderRadius};
  border-top-right-radius: ${bubbleBorderRadius};
  border-bottom-left-radius: ${({ myMessage }) =>
    myMessage && bubbleBorderRadius};
  border-bottom-right-radius: ${({ myMessage }) =>
    !myMessage && bubbleBorderRadius};
  color: ${({ myMessage }) => (!myMessage ? '#000' : '#fff')};
  padding: 12px 16px;
  margin: 10px 18px;
  white-space: pre-wrap;
  text-align: left;
  box-shadow: ${({ theme }) => theme.boxShadow};
`

const Message = ({ children, myMessage }) => (
  <MsgContainer myMessage={myMessage}>
    <MsgBubble myMessage={myMessage}>{children}</MsgBubble>
  </MsgContainer>
)

Message.propTypes = {
  children: PropTypes.node.isRequired,
  myMessage: PropTypes.bool,
}

Message.defaultProps = {
  myMessage: false,
}
export default Message
