import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { useDispatch } from 'react-redux'
import { PrimaryButton } from '../../../../../../components/Button/Button'
import { addToHintCount } from '../../../../reducers/adventureSlice'

const HintButton = styled(PrimaryButton)`
  text-transform: unset;
  width: 100%;
  min-height: 22px;
  font-size: 1.2em;
  grid-column: span 2;

  :disabled {
    background-color: ${({ theme }) => theme.color.tertiary};
    border-color: ${({ theme }) => theme.color.tertiary};
    opacity: 1;
  }
`

const Hint = ({ hintNumber, hint, hintPlaceholder, onClick }) => {
  const [showHint, toggleShowHint] = useState(false)
  const dispatch = useDispatch()
  const placeholder = hintPlaceholder || `Show Hint ${hintNumber}`
  return (
    <HintButton
      onClick={() => {
        if (!showHint) {
          toggleShowHint(true)
          dispatch(addToHintCount())
          if (onClick) {
            onClick()
          }
        }
      }}
      disabled={showHint}
    >
      {!showHint ? placeholder : hint}
    </HintButton>
  )
}

Hint.propTypes = {
  hint: PropTypes.string.isRequired,
  hintNumber: PropTypes.number,
  hintPlaceholder: PropTypes.string,
  onClick: PropTypes.func,
}

Hint.defaultProps = {
  hintPlaceholder: '',
  hintNumber: '',
  onClick: null,
}

export default Hint
